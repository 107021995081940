import React, { useEffect, useState, useRef } from "react";
import { SpringValue, animated } from "react-spring";

type Props = {
  svgPos?: {
    x: number;
    y: number;
    height?: number;
    width?: number;
    scale: number;
  };
  frame?: SpringValue<number>;
  floor?: SpringValue<number>;
  bgOpacity?: SpringValue<number>;
  bgColor?: string;
};

export default function StudentConcern(props : Props) {
  const frame = props.frame === undefined ? 0 : props.frame;
  const floor = props.floor === undefined ? 0 : props.floor;
  const bgOpacity = props.bgOpacity === undefined ? 0 : props.bgOpacity;
  const bgColor = props.bgColor === undefined ? "azure" : props.bgColor;


  const content = (
    <g>
      <animated.rect
        id="bg"
        opacity={bgOpacity}
        height={5568}
        width={6780}
        fill={bgColor}
      />

      <g
        id="layer1"
        fill="none"
        stroke="#000000"
        strokeWidth={7}
        strokeLinecap="butt"
        strokeLinejoin="miter"
      >
        <g id="speech">
          <path
            d="m 3079.4731,2064.6467 c 0,0 -9.5438,222.6892 -184.5139,337.2151 0,0 222.6892,-79.5319 299.0397,-343.5776"
            id="path1367"
          />
          <path d="m 3193.9989,2058.2842 v 0 l 45.1753,-9.2611" id="path1369" />
          <path
            d="m 3079.4731,2064.6467 c 0,0 -835.8363,-72.6327 -1126.4007,-381.1702 0,0 -302.5465,-200.6991 -188.7171,-676.9852 113.8293,-476.28606 871.6933,-653.02114 1494.7594,-676.98522 623.066,-23.96408 1165.2533,29.9551 1386.921,266.60037 221.6677,236.64528 335.4971,428.35795 47.9282,958.56315 -287.569,530.2052 -1454.7897,494.3535 -1454.7897,494.3535"
            id="path1371"
          />
        </g>
        <g id="background">
          <g id="door">
            <path
              d="m 366.90006,2552.1137 c 0,0 -21.58236,-291.3619 -5.39559,-377.6913 16.18677,-86.3294 5.39559,-269.7794 0,-329.1309 -5.39559,-59.3515 26.97795,-215.8236 26.97795,-291.3618 0,-75.5383 -21.58236,-199.6368 -16.18677,-248.1971 5.39559,-48.5603 -16.18677,-242.8015 -16.18677,-285.9663"
              id="path1378"
            />
            <path
              d="m 505.73996,2516.9384 c 0,0 5.8807,-202.884 0,-238.1682 -5.8807,-35.2842 -17.64209,-229.3472 -8.82104,-302.8559 8.82104,-73.5087 0,-255.8104 0,-285.2138 0,-29.4035 23.52278,-188.1824 20.58244,-252.87 -2.94035,-64.6877 -17.6421,-217.5858 -11.7614,-255.8104"
              id="path1383"
            />
            <path
              d="M 516.77663,1163.6203 375.3614,1000.3818 c 0,0 98.64149,0.8729 122.21069,-2.61882 23.56921,-3.49173 64.59708,-3.49173 87.29335,-0.87293 22.69627,2.6188 115.22722,-1.74587 144.90696,5.23755 29.67974,6.9835 110.86255,7.8565 126.57536,6.1106 15.7128,-1.7459 154.50924,-7.8564 168.47614,-6.9835 13.967,0.8729 117.846,12.2211 137.9235,9.6023 20.0775,-2.6188 138.7964,-5.2376 158.001,-5.2376 19.2045,0 128.3212,0.8729 139.6693,5.2376 11.3482,4.3647 -36.6632,34.9173 -58.4865,69.8347 -21.8233,34.9173 -56.7407,72.4534 -62.8512,74.1993 -6.1106,1.7459 -72.4535,-11.3481 -104.752,-6.1105 -32.2986,5.2376 -96.8957,7.8564 -116.1002,4.3646 -19.2045,-3.4917 -68.0888,-8.7293 -88.1663,-5.2376 -20.0774,3.4918 -137.05054,-6.9834 -184.18895,-0.8729 -47.1384,6.1105 -126.57535,9.6023 -148.39869,7.8564 -21.82334,-1.7459 -145.77989,-0.8729 -159.74683,0.8729"
              id="path1386"
            />
            <path
              d="m 1467.3587,1046.4082 c 0,0 9.549,31.0341 8.7532,58.8853 -0.7957,27.8512 -1.5915,47.7449 0,63.6598 1.5915,15.915 -11.1404,59.6811 -11.9362,74.0046 -0.7957,14.3234 -7.1617,138.46 -3.9787,153.5793 3.183,15.1192 6.3659,61.2725 6.3659,91.5109 0,30.2384 -1.5914,118.5664 -0.7957,139.2559 0.7957,20.6894 -1.5915,93.1025 -4.7745,109.0174 -3.183,15.915 -7.1617,116.1792 -5.5702,148.0091 1.5915,31.8299 5.5702,78.779 0.7957,118.5664 -4.7745,39.7874 -19.7603,189.827 -18.2686,225.6266 1.4916,35.7996 4.4749,95.4655 -4.475,158.1148 -8.9499,62.6493 -1.4916,114.857 0,132.7568 1.4917,17.8997 0,52.2077 -1.4916,65.6325"
              id="path1388"
            />
            <path
              d="m 1333.5341,2570.1111 c 0,0 1.4917,-162.5897 -2.9832,-181.9812 -4.475,-19.3914 13.4248,-76.074 8.9498,-120.8235 -4.4749,-44.7495 -2.9833,-168.5563 0,-217.7808 2.9833,-49.2244 13.4249,-238.6638 11.9332,-284.9049 -1.4916,-46.2411 -13.4248,-150.6566 -5.9666,-214.7975 7.4583,-64.1409 14.9165,-171.5396 7.4583,-207.3392 -7.4583,-35.7995 -1.4917,-95.4655 -1.4917,-108.8903 0,-13.4249 -13.4248,-47.7328 -10.4415,-68.6159"
              id="path1390"
            />
            <path
              d="m 1188.8442,1889.9192 c 0,0 -49.2244,11.9332 -49.2244,26.8497 0,14.9165 14.9165,53.6993 26.8497,61.1576 11.9332,7.4582 26.8496,11.9332 34.3079,16.4081 7.4582,4.475 56.6827,-25.358 46.2411,-52.2077 -10.4415,-26.8497 -28.3413,-49.2244 -50.7161,-56.6826"
              id="path1392"
            />
            <path
              d="m 693.05549,1656.7327 c 0,0 -1.3201,-79.2064 0,-118.8096 1.32011,-39.6031 5.28043,-105.6084 -1.3201,-135.9708 -6.60053,-30.3625 -15.84127,-62.045 -1.32011,-77.8863 14.52116,-15.8413 58.08465,-22.4418 75.24603,-19.8016 17.16137,2.6402 59.40475,5.2805 79.20634,3.9603 19.80158,-1.3201 48.84391,3.9604 81.84655,1.3202 33.00264,-2.6403 158.4127,-11.881 171.6137,-9.2408"
              id="path1394"
            />
            <path
              d="m 1130.0105,1302.9443 c 0,0 -14.5212,106.9286 -1.3201,191.4154 13.201,84.4867 10.5608,117.4894 2.6402,135.9708 -7.9206,18.4815 -56.7646,48.844 -116.1693,46.2037 -59.40477,-2.6402 -68.64551,-1.3201 -80.52646,1.3201 -11.88096,2.6403 -55.44444,-10.5608 -124.08994,-7.9206 -68.6455,2.6402 -96.77632,-4.0585 -117.12226,5.4917"
              id="path1396"
            />
            <path
              d="m 795.75542,1324.6297 c 0,0 -21.5069,39.1035 -37.14828,55.7225 -15.64139,16.6189 -51.81209,57.6776 -53.76726,72.3414"
              id="path1398"
            />
            <path
              d="m 789.8899,1351.0246 c 0,0 90.91555,87.9828 123.17591,112.4224 32.26035,24.4397 169.12249,124.1535 180.85349,134.907"
              id="path1400"
            />
            <path
              d="m 727.32437,1427.2763 c 0,0 70.38623,83.0949 100.69141,105.5794 30.30518,22.4844 108.5121,79.1845 115.35521,88.9603"
              id="path1402"
            />
            <path
              d="m 720.48126,1577.8246 c 0,0 57.67761,59.6328 66.47588,66.4759"
              id="path1404"
            />
            <path
              d="m 720.48126,1532.8557 c 0,0 157.39143,-196.4949 161.30178,-209.2036"
              id="path1406"
            />
            <path
              d="m 918.93132,1339.2935 c 0,0 63.54313,68.4311 86.02758,83.0949 22.4845,14.6638 103.6242,76.2517 111.4449,85.05"
              id="path1408"
            />
            <path
              d="m 1017.6676,1325.6073 c 0,0 43.0138,39.1035 55.7224,58.6552 12.7086,19.5517 12.7086,16.619 22.4845,22.4845"
              id="path1410"
            />
            <path
              d="m 1111.5159,1346.1366 c 0,0 -48.8794,61.588 -65.4983,79.1845 -16.619,17.5966 -50.83453,56.7001 -65.49833,80.1621 -14.66379,23.4621 -91.89313,119.2656 -100.69141,129.0415"
              id="path1412"
            />
            <path
              d="m 1000.071,1315.8314 c 0,0 -7.82069,26.3949 -22.48448,43.0139 -14.6638,16.6189 -55.72244,69.4086 -61.58796,83.0948 -5.86551,13.6862 -38.12587,57.6776 -56.70001,71.3638 -18.57415,13.6862 -55.72243,71.3638 -60.61037,83.0949"
              id="path1414"
            />
            <path
              d="m 968.78824,1640.3902 c 0,0 43.01376,-61.588 67.45346,-77.2294 24.4397,-15.6413 60.6104,-64.5207 60.6104,-75.2741"
              id="path1416"
            />
          </g>
          <animated.g
            id="floor"
            opacity={floor}
            >
            <path
              d="m 280.72563,2596.7121 c 0,0 342.6504,-16.5133 388.0619,0 45.4115,16.5132 140.36281,-24.7699 218.80086,0 78.43804,24.7699 389.95701,29.0825 497.23181,21.7683 107.2748,-7.3141 268.187,17.0665 338.8909,0 70.7038,-17.0664 204.7973,7.3142 253.5586,-9.7522"
              id="path1418"
            />
            <path
              d="m 2708.6888,2616.0424 c 0,0 60.9516,-9.7523 80.4561,-4.8761 19.5045,4.8761 165.7883,-12.1904 182.8548,-7.3142"
              id="path1420"
            />
            <path
              d="m 3388.9086,2623.3566 c 0,0 324.2625,-4.8762 358.3953,-9.7523 34.1329,-4.8761 273.0632,0 302.32,4.8761 29.2567,4.8762 153.598,-2.438 173.1025,-4.8761"
              id="path1422"
            />
            <path
              d="m 5166.2886,2614.2665 c 0,0 143.6411,14.3641 210.6735,9.5761 67.0325,-4.788 239.4017,-14.3641 282.494,0 43.0923,14.3641 201.0975,-19.1521 325.5864,-14.3641 124.4888,4.788 435.711,28.7282 497.9555,33.5162"
              id="path1424"
            />
          </animated.g>
        </g>
        <g id="table">
          <g id="chairs">
            <path
              d="m 1066.6821,4629.7486 c 0,0 -3.7296,74.5932 0,94.4847 3.7297,19.8915 3.7297,89.5118 4.9729,98.2143 1.2432,8.7026 1.2432,34.8102 1.2432,54.7017 0,19.8915 0,48.4855 2.4865,69.6202 2.4864,21.1348 -11.189,23.6212 -6.2161,55.9449 4.9729,32.3237 -3.7297,62.161 -11.189,74.5932"
              id="path1571"
            />
            <path
              d="m 1435.9183,4616.0732 c 0,0 -1.2432,100.7008 3.7296,134.2677 4.9729,33.5669 0,110.6465 -4.9728,139.2406 -4.9729,28.594 6.2161,43.5126 2.4864,62.1609 -3.7297,18.6483 2.4864,42.2695 2.4864,60.9178 0,18.6483 -6.2161,21.1347 8.7026,29.8372"
              id="path1573"
            />
            <path
              d="m 1476.9445,5186.7109 c 0,0 60.9178,-6.2161 74.5932,0"
              id="path1575"
            />
            <path
              d="m 1494.676,5172.2915 c 0,0 -4.3759,-112.5227 -10.6272,-130.6513 -6.2512,-18.1287 -5.6261,-61.8875 -1.2502,-92.5186 4.3759,-30.6312 6.8764,-119.3991 2.5005,-142.5287 -4.3759,-23.1297 0.6251,-137.5277 4.3759,-171.9097 3.7507,-34.3819 3.7507,-46.8844 -29.381,-50.6352 -33.1316,-3.7507 -110.0221,-2.5005 -148.7799,0 -38.7578,2.5006 -141.2785,4.3759 -173.785,4.3759 -32.5066,0 -98.7699,-8.1266 -104.396,-0.6251 -5.6262,7.5015 -3.7508,21.8794 -5.6262,55.0111 -1.8753,33.1317 -9.3768,151.2804 -4.3758,180.6614 5.001,29.3809 0,67.5136 -1.8754,83.7668 -1.8754,16.2533 5.6261,64.388 0,90.0182 -5.6262,25.6301 -5.001,83.1417 -0.6251,100.0201 4.3758,16.8784 0,42.5086 -0.6252,57.5116"
              id="path1577"
            />
            <path
              d="m 932.6726,5184.1297 c 0,0 57.3726,-10.2976 69.1413,-4.4133"
              id="path1585"
            />
            <path
              d="m 932.6726,5153.2368 c 0,0 -4.41328,-198.5975 1.47109,-233.9037 5.88437,-35.3062 1.47109,-86.7945 0,-116.2163 -1.47109,-29.4219 -7.35546,-192.7131 -2.94218,-216.2506 4.41327,-23.5375 11.76874,-197.1264 8.82655,-239.7881 -2.94218,-42.6617 -11.76874,-219.1928 -1.47109,-283.9208 10.29765,-64.7281 39.20956,-216.032 19.1242,-241.2592 -20.08536,-25.2271 18.68967,-106.3323 2.4574,-155.0291"
              id="path1587"
            />
            <path
              d="m 960.9613,3656.5963 c 0,0 9.36245,-10.8604 53.5532,-2.6215 44.1907,8.239 186.1254,-6.3664 200.3563,-8.6134 14.2309,-2.247 135.5682,-7.49 159.9105,-2.247 24.3424,5.243 56.5492,3.745 77.5211,2.6215 20.9719,-1.1235 82.3895,7.4899 97.7439,1.498"
              id="path1589"
            />
            <path
              d="m 1552.136,3667.3244 c 0,0 -8.06,76.5705 -6.3329,101.9021 1.7272,25.3316 1.1515,127.8094 -2.3028,152.5653 -3.4543,24.7559 9.7872,122.6279 10.3629,160.6253 0.5757,37.9974 -6.3329,76.5706 -5.1815,95.5693 1.1515,18.9986 8.6358,96.7206 7.4844,115.7193 -1.1515,18.9987 -1.7272,76.5705 0,84.0548 1.7271,7.4844 0,130.1123 0,153.7168 0,23.6044 -2.8786,139.3238 3.4543,173.8668 6.3329,34.5431 5.1814,125.5066 3.4543,147.9596 -1.7272,22.453 -4.03,139.8995 0,164.6554 4.03,24.7559 6.3329,107.6593 5.1814,129.5366"
              id="path1591"
            />
            <path
              d="m 1116.8931,4127.8989 c 0,0 43.1789,-18.423 116.2951,-16.6959 73.1162,1.7272 163.5039,2.8786 172.1397,1.1515 8.6358,-1.7272 12.0901,7.4843 13.2415,24.1801 1.1515,16.6959 4.0301,176.7455 4.0301,187.6842"
              id="path1593"
            />
            <path
              d="m 1122.0746,4158.4119 c 0,0 -2.3029,169.8369 -4.03,183.0784 0,0 199.7742,-8.0601 219.9243,-0.5757 0,0 58.1475,-10.9387 74.8434,-2.3029"
              id="path1595"
            />
            <path
              d="m 1119.196,3990.878 c 0,0 -7.4843,33.9673 32.8159,31.0887 40.3003,-2.8785 88.6606,-13.8172 145.6567,-2.3028 56.9961,11.5143 118.0222,-7.4844 117.4465,-31.6645 -0.5757,-24.1802 -12.6658,-148.5353 -7.4843,-169.2612 0,0 -206.6828,11.5144 -280.3747,4.0301 0,0 -2.8786,118.598 -8.0601,168.1097 z"
              id="path1597"
            />
            <path
              d="m 858.48963,3790.3278 c 0,0 -6.40664,-164.1701 -1.60166,-200.2074 4.80498,-36.0374 8.80913,-84.888 2.40249,-110.5146 0,0 36.03735,-20.0207 81.68465,-10.4108 0,0 65.66809,-16.8174 95.29879,-13.6141 29.6307,3.2034 72.0747,-7.2074 84.8879,-11.2116 12.8133,-4.0041 68.8714,-6.4066 78.4814,-3.2033 9.6099,3.2033 24.0249,-6.4066 54.4564,-7.2075 30.4315,-0.8008 60.0622,-1.6016 68.0705,3.2034"
              id="path1599"
            />
            <path
              d="m 1310.9585,3455.5809 c 0,0 6.4067,128.9337 5.6058,148.1536"
              id="path1601"
            />
            <path
              d="m 1802.6681,3405.9295 c 0,0 -108.9129,3.2033 -143.3486,12.8133 0,0 -19.2199,63.2655 -13.6141,160.1659 5.6058,96.9005 5.6058,45.6473 0.8008,96.9005"
              id="path1603"
            />
            <path
              d="m 1730.5934,3638.971 c 0,0 4.805,-126.5311 3.2033,-148.9544 0,0 24.8257,-7.2074 40.0415,-4.0041"
              id="path1605"
            />
            <path
              d="m 3986.3757,3740.7167 c 0,0 -4.8853,-159.1201 -0.6979,-179.359 4.1874,-20.239 4.1874,-106.7779 -0.6979,-129.1106 -4.8853,-22.3326 -9.0726,-58.6231 -5.5832,-79.56"
              id="path1609"
            />
            <path
              d="m 3990.5631,3342.2187 c 0,0 -2.7916,8.3747 78.1642,4.8853 80.9558,-3.4895 172.38,-13.9579 198.9,-12.5621 26.52,1.3957 56.5295,0 80.9558,-2.0937 24.4264,-2.0937 57.2274,-5.5832 57.9253,9.0726 0.6979,14.6558 -4.1873,90.0284 -2.0937,101.1948 2.0937,11.1663 -3.4894,90.0284 -6.281,103.2884"
              id="path1611"
            />
            <path
              d="m 4107.1115,3440.6219 c 0,0 -5.5831,-14.6558 27.2179,-13.9579 32.8011,0.6979 65.6021,-9.7706 94.2158,-8.3748 28.6137,1.3958 109.5695,3.4895 120.7358,-0.6979"
              id="path1613"
            />
            <path
              d="m 4339.2192,3424.2299 c 0,0 0,39.8862 0.7977,53.0486 0.7978,13.1625 8.3761,57.0373 -5.9829,65.0145 -14.359,7.9773 -64.6156,20.342 -82.9633,22.3363 -18.3476,1.9943 -80.5701,13.1624 -132.821,10.7693"
              id="path1615"
            />
            <path
              d="m 4084.7453,3573.8031 c 0,0 3.1909,-28.718 -1.9943,-55.8407 -5.1852,-27.1226 -4.7864,-69.0031 1.5954,-86.1541"
              id="path1617"
            />
            <path
              d="m 4103.0929,3743.7183 c 0,0 2.3932,-72.194 -2.792,-93.7326 -5.1852,-21.5385 -8.3761,-18.7465 13.9602,-25.926 22.3362,-7.1795 43.4759,-12.3647 63.0202,-9.9715 19.5442,2.3931 24.7294,1.1966 30.3135,-0.3989 5.584,-1.5954 19.9431,0.3989 32.3078,3.5898 12.3647,3.1909 71.3963,-2.7921 80.5701,-5.1852"
              id="path1619"
            />
            <path
              d="m 4121.0417,3767.2512 c 0,0 48.2623,-16.7522 73.7895,-8.775 25.5271,7.9772 67.0088,2.792 78.5758,0.7977 11.567,-1.9943 53.0486,7.1795 58.6327,-14.7579 5.5841,-21.9374 -0.3989,-92.1371 6.7806,-114.8722"
              id="path1621"
            />
            <path
              d="m 5305.489,3334.1189 c 0,0 21.9041,18.7749 118.908,21.9041 97.004,3.1291 167.41,-1.5646 179.9267,9.3874"
              id="path1623"
            />
            <path
              d="m 5621.7792,3373.6911 c 0,0 13.2515,0.7795 16.3696,31.9597 3.118,31.1801 2.3385,70.9348 4.677,79.5093 2.3385,8.5745 -5.4566,107.5715 -7.7951,130.9566 -2.3385,23.3851 -3.8975,79.5094 -1.559,95.0994 2.3385,15.5901 0.7795,48.3292 -3.118,67.8168"
              id="path1625"
            />
            <path
              d="m 5560.9779,3757.9863 c 0,0 -5.4565,-83.4068 5.4565,-84.1863 0,0 -212.8044,-2.3386 -223.7175,-7.7951"
              id="path1627"
            />
            <path
              d="m 5357.5275,3563.89 c 0,0 113.028,16.3695 134.0746,21.0466 21.0466,4.677 67.0373,9.354 76.3913,8.5745"
              id="path1629"
            />
            <path
              d="m 5356.748,3429.8154 c 0,0 36.6367,14.031 94.3199,19.4875 57.6833,5.4566 102.115,10.1336 110.6895,13.2516 0,0 6.236,130.9566 6.236,130.9566"
              id="path1631"
            />
            <path
              d="m 5357.5275,3563.89 c 0,0 101.3355,11.6925 117.705,17.9285 16.3696,6.2361 72.9088,10.223 92.7609,11.6926"
              id="path1633"
            />
            <path
              d="m 6175.2984,3931.4866 c 0,0 10.8882,-81.2429 4.1878,-109.7198 -6.7005,-28.4769 -15.9136,-61.1416 -10.8883,-91.2936 5.0254,-30.152 -0.8375,-144.0596 -5.8629,-168.3487 -5.0253,-24.2891 -3.3502,-146.5722 5.0254,-164.9985"
              id="path1635"
            />
            <path
              d="m 6182.1917,3395.0934 c 0,0 82.8809,63.5219 97.4002,68.3617 14.5193,4.8397 86.5107,50.2125 93.1654,57.4721 6.6547,7.2597 55.0523,19.9641 58.0772,38.1132 3.0248,18.1491 -0.605,83.4859 1.2099,99.8201 1.8149,16.3342 13.3094,116.1543 10.8895,145.1929 -2.4199,29.0385 0,86.5107 0,104.0548 0,17.5442 -6.6547,110.7096 -5.4448,125.2289 1.21,14.5193 6.6547,84.6958 6.6547,112.5245 0,27.8286 3.0249,142.773 1.8149,171.2066 -1.2099,28.4336 1.21,124.0189 10.8895,167.5768 9.6795,43.5578 7.2596,300.0652 -4.8398,323.6591"
              id="path1637"
            />
            <path
              d="m 6380.6219,4813.1438 c 0,0 39.6559,0.821 50.3698,-3.388"
              id="path1639"
            />
            <path
              d="m 6367.8564,4805.1641 c 0,0 3.8264,-79.5886 2.6785,-104.4601 -1.1479,-24.8714 0,-83.415 2.2958,-102.1642 2.2958,-18.7493 -3.8264,-249.0972 -6.8875,-262.1069"
              id="path1641"
            />
            <path
              d="m 6234.6985,3997.7986 c 0,0 26.7847,29.0804 54.7172,48.9776 27.9326,19.8972 39.7943,26.402 44.0033,32.9068"
              id="path1643"
            />
            <path
              d="m 6345.2808,3987.85 c 0,0 12.2444,-92.2157 2.2958,-132.01"
              id="path1645"
            />
            <path
              d="m 6236.2291,3930.4543 c 0,0 -6.5049,-79.9712 2.2958,-107.5211"
              id="path1647"
            />
            <path
              d="m 6229.7242,3768.216 c 0,0 70.0227,48.595 78.0581,51.2734 8.0354,2.6785 28.3152,17.984 32.9069,21.8104 4.5916,3.8263 11.0964,6.8874 16.836,3.8263"
              id="path1649"
            />
            <path
              d="m 6342.9632,3727.0798 c 0,0 -99.7582,-72.0477 -108.0714,-62.8108 -8.3132,9.2369 -0.9237,-124.6978 -12.9316,-150.5611"
              id="path1651"
            />
            <path
              d="m 6233.6764,3505.4197 c 0,0 24.0135,25.6507 41.4778,33.8371 17.4644,8.1864 66.037,40.3863 72.5862,48.027 6.5491,7.6406 18.5558,12.5525 25.6507,12.5525"
              id="path1653"
            />
            <path
              d="m 6355.381,3719.3578 c 0,0 10.3695,-51.8472 9.8237,-73.1319 -0.5458,-21.2847 -3.2746,-32.1998 8.1864,-46.3896"
              id="path1655"
            />
            <path
              d="m 5926.0404,4430.9882 c 0,0 -7.7383,102.1464 -4.643,136.1952 3.0954,34.0488 12.0739,115.8959 9.6365,137.0202 -2.4375,21.1242 0.8124,44.6858 1.6249,69.0599"
              id="path1657"
            />
            <path
              d="m 5974.0948,4814.6995 c 0,0 -46.008,-21.4455 -51.0681,-32.0256"
              id="path1659"
            />
            <path
              d="m 6004.4477,4761.5137 c 0,0 -7.3601,-73.1409 -5.9801,-90.6211 1.38,-17.4802 -11.0401,-68.0807 -10.5801,-94.301 0.46,-26.2203 7.3601,-70.8408 6.9001,-86.021 -0.4601,-15.1802 -0.4601,-46.9205 2.3,-57.5006"
              id="path1661"
            />
          </g>
          <path
            d="m 361.36614,4258.9581 c 0,0 -5.95658,97.2909 -3.97105,115.1606 1.98552,17.8698 1.98552,125.0883 -3.97106,154.8712 -5.95659,29.783 -19.85528,206.495 -25.81187,228.3358 -5.95658,21.8408 25.81187,55.5948 17.86976,83.3922 -7.94212,27.7974 1.98552,113.1751 3.97105,133.0304"
            id="path1531"
          />
          <path
            d="m 389.16354,4961.8351 -2.96346,5.9611 c 0,0 66.2649,-19.6724 75.5834,-19.6724 0,0 -10.35389,-45.5571 -6.21234,-66.2648 4.14156,-20.7078 0,-66.2649 -1.03539,-85.9373 -1.03538,-19.6724 1.03539,-122.1759 2.07078,-139.7775 1.03539,-17.6017 8.28311,-101.4682 5.17695,-104.5743 -3.10617,-3.1062 1.03539,-60.0526 -1.03539,-85.9373 -2.07078,-25.8847 -5.17695,-123.2113 -1.03539,-134.6006 4.14155,-11.3893 10.35389,-50.734 8.28311,-72.4772"
            id="path1536"
          />
          <path
            d="m 464.15979,4335.6585 c 0,0 62.30803,7.701 86.81118,3.5004 24.50316,-4.2005 48.30622,7.0009 61.60794,7.0009 13.30171,0 46.90604,-11.2014 68.60884,-3.5004 21.70279,7.701 178.52299,14.7019 194.62507,9.8013"
            id="path1538"
          />
          <path
            d="m 413.7533,4250.9476 c 0,0 51.80667,-15.402 101.51307,-1.4002 49.70641,14.0018 95.91236,6.3008 127.41642,3.5004 31.50405,-2.8003 153.31975,-5.6007 165.22128,-3.5004 11.90153,2.1003 72.10929,-6.3008 84.01082,-10.5014"
            id="path1540"
          />
          <path
            d="m 364.91961,4214.3371 c 0,0 86.11672,-113.0282 96.88131,-133.4809 10.76459,-20.4527 93.65194,-142.0926 111.95174,-160.3924 18.29981,-18.2998 77.50505,-107.6459 86.11672,-111.9518"
            id="path1542"
          />
          <path
            d="m 700.77483,3806.3591 c 0,0 48.44065,-2.1529 61.35816,0 12.91751,2.1529 35.52315,1.0765 50.59358,-1.0764 15.07042,-2.153 104.41652,-11.8411 124.86924,-8.6117"
            id="path1547"
          />
          <path
            d="m 1605.2982,3806.1792 c 0,0 117.4088,-10.6736 123.8129,-2.1348"
            id="path1551"
          />
          <path
            d="m 1598.8941,4273.6796 c 0,0 117.4088,-6.4042 143.0252,0"
            id="path1553"
          />
          <path
            d="m 1598.8941,4346.2595 c 0,0 53.3676,-4.2694 81.1188,2.1347 27.7512,6.4042 32.0206,4.2695 53.3676,0"
            id="path1555"
          />
          <path
            d="m 2523.2214,3821.1221 c 0,0 78.9841,2.1347 108.87,-2.1347 29.8858,-4.2694 140.8905,-12.8082 181.4499,-8.5388 40.5594,4.2694 108.8699,-2.1347 123.8129,-4.2694"
            id="path1557"
          />
          <path
            d="m 2911.7647,3813.9503 c 0,0 53.4269,50.9984 70.4263,93.497 16.9995,42.4987 49.7841,93.4971 78.9261,120.2105 29.1419,26.7135 98.354,180.9228 109.2822,200.3508"
            id="path1559"
          />
          <path
            d="m 2501.3491,4255.9363 c 0,0 44.9272,2.4285 64.3551,-3.6428 19.428,-6.0712 98.3541,-2.4285 120.2105,3.6428 21.8565,6.0712 155.4236,-7.2855 182.1371,-10.9282 26.7134,-3.6428 150.5666,-8.4998 176.0658,-8.4998 25.4992,0 89.1946,-11.352 104.5008,0.1277"
            id="path1561"
          />
          <path
            d="m 2520.8471,4337.9143 c 0,0 94.4016,9.1146 126.3028,7.8125 31.9012,-1.302 148.4383,-14.974 185.5479,-13.0209 37.1095,1.9532 152.3445,-1.302 165.3654,-4.5573"
            id="path1563"
          />
          <path
            d="m 3020.8498,4268.9035 c 0,0 -13.6719,95.7037 -9.1146,115.8861 4.5573,20.1824 -4.5573,91.7973 -1.3021,106.7714 3.2552,14.974 5.8594,84.6359 5.2084,113.2818 -0.6511,28.646 -18.8804,77.4744 -16.2762,111.3288 2.6042,33.8543 0.6511,168.6207 3.9063,185.5479 3.2552,16.9271 -14.974,100.912 22.7866,93.0994"
            id="path1565"
          />
          <path
            d="m 3059.9125,4986.3553 c 0,0 65.1046,1.3021 71.615,-9.1146"
            id="path1567"
          />
          <path
            d="m 3120.4597,4962.9177 c 0,0 9.7657,-27.3439 9.7657,-96.3547 0,-69.0108 5.8594,-174.4801 10.4167,-197.9177 4.5574,-23.4377 5.2084,-104.1673 2.6042,-125.0007 -2.6042,-20.8335 9.1147,-133.4643 4.5573,-151.6935 -4.5573,-18.2293 0.842,-125.1217 11.7189,-144.5321"
            id="path1569"
          />
          <path
            d="m 4071.3495,4416.7474 c 0,0 232.2633,-10.4893 242.0033,1.4985"
            id="path1762"
          />
          <path
            d="m 4075.6859,4365.8391 c 0,0 -3.8687,83.1773 -3.8687,123.7987 0,40.6215 17.4092,251.4662 9.6717,290.1533 -7.7374,38.6871 -9.6717,179.895 -5.803,234.0569 0,0 -43.2967,22.5178 -80.0092,8.2823 0,0 11.2385,-207.5385 8.2416,-250.2449 -2.997,-42.7065 -5.9939,-387.3551 -0.7493,-421.0708"
            id="path1760"
          />
          <path
            d="m 4034.0675,4346.0008 c 0,0 184.7293,-4.0511 226.0504,-0.8102 41.321,3.2409 89.1238,6.4817 107.7588,4.8613"
            id="path1766"
          />
          <path
            d="m 4003.169,4350.8146 c 0,0 -66.3275,-168.4775 -71.1888,-196.8351 -4.8613,-28.3575 -31.5984,-96.4157 -42.9414,-118.2916 -11.3431,-21.8758 -81.8319,-183.9191 -81.8319,-197.6928"
            id="path1768"
          />
          <path
            d="m 3968.44,4393.8036 c 0,0 -46.1824,-106.1384 -46.9926,-121.5325 -0.8102,-15.3941 -21.0656,-59.1458 -24.3065,-68.0581 -3.2408,-8.9124 -36.4597,-71.2991 -40.5108,-89.1239"
            id="path1770"
          />
          <path
            d="m 3850.9586,4021.104 c 0,0 0.8102,139.3573 4.8613,171.7659 4.0511,32.4087 8.1022,143.4083 4.8613,177.4374 -3.2409,34.0291 -12.9635,248.7365 -7.292,277.9042 5.6716,29.1678 4.0511,43.7517 3.2409,72.1093 0,0 -10.5328,21.8759 -64.8173,-2.4306 0,0 4.8613,-82.6421 9.7226,-106.1384 4.8613,-23.4963 -0.8102,-148.2696 1.6204,-170.1454 2.4307,-21.8759 5.6715,-139.3573 4.0511,-159.6127 -1.6204,-20.2554 -11.343,-356.4952 -1.6204,-393.7651"
            id="path1772"
          />
          <path
            d="m 3837.7777,3820.7272 c 0,0 160.5586,1.4209 187.5552,-1.4209 26.9966,-2.8417 89.515,-8.5252 122.1951,-7.1043 32.6801,1.4208 183.2926,-7.1044 207.4474,-14.2088 24.1549,-7.1043 26.9966,-4.2626 48.3097,-2.8417"
            id="path1774"
          />
          <path
            d="m 5320.4502,3788.3285 c 0,0 248.3577,29.7329 289.4591,27.9839 41.1015,-1.749 76.9559,-5.247 93.5714,-1.749 16.6155,3.498 69.0854,3.498 86.5754,0.8745 17.4899,-2.6235 181.8957,-10.4939 211.6286,-6.1215"
            id="path1778"
          />
          <path
            d="m 6028.7942,3789.203 c 0,0 29.733,50.7209 53.3444,67.3364 23.6115,16.6155 62.0894,76.0814 82.2029,108.4378 20.1135,32.3565 52.4699,95.3204 64.7129,111.0614 12.243,15.7409 55.9679,69.0854 63.8384,88.3243"
            id="path1780"
          />
          <path
            d="m 5324.8227,4382.1131 c 0,0 240.4872,1.749 277.2161,-4.3725 36.729,-6.1215 160.0333,-0.8745 191.5153,0.8745 31.4819,1.749 151.2882,0.8745 185.3937,-2.6235 34.1054,-3.498 183.6447,6.1215 206.3817,0.8745"
            id="path1782"
          />
          <path
            d="m 6186.204,4310.4042 c 0,0 -2.6235,48.9719 -0.8745,66.4619 1.749,17.49 -14.8665,87.4499 -13.992,116.3083 0.8745,28.8585 5.247,154.7863 4.3725,181.8958 -0.8745,27.1094 -2.6235,90.0734 -2.6235,117.1828 0,27.1095 1.749,125.9278 3.498,145.1668 0,0 40.2269,48.9719 93.5714,34.1055"
            id="path1784"
          />
          <path
            d="m 6285.8968,4995.1367 c 0,0 1.749,-108.4378 -0.8745,-133.7983 -2.6235,-25.3605 6.1215,-80.4539 6.996,-111.0613 0.8745,-30.6075 1.749,-93.5714 -1.749,-136.4218 -3.498,-42.8505 5.247,-146.9158 2.6235,-182.7703 -2.6235,-35.8544 1.749,-131.1748 6.996,-145.1667 0,0 -321.8155,7.8704 -367.2895,14.8664 -45.4739,6.996 -122.4298,4.3725 -155.6607,6.996 -33.231,2.6235 -115.4339,-2.6235 -157.4098,4.3725 -41.9759,6.996 -30.6074,8.745 -41.9759,6.996 -11.3685,-1.749 -220.3737,-4.3725 -250.1067,-10.494"
            id="path1786"
          />
          <path
            d="m 6028.7942,3789.203 c 0,0 28.8585,48.9719 45.4739,62.9639 16.6155,13.992 86.5754,104.9398 99.6929,132.0493 13.1175,27.1095 72.5834,119.8063 90.9479,140.7943 18.3644,20.988 17.4899,2.6234 27.9839,39.3524"
            id="path1788"
          />
        </g>
      </g>
      <g id="student" fill="none" stroke="#000000" strokeWidth={7}>
        <g id="g3119">
          <path
            d="m 2311.7337,2737.787 c 0,0 7.8898,49.3117 -9.8624,74.9538 -17.7522,25.6421 -32.6999,27.7619 -36.1007,41.365"
            id="path2118"
          />
          <path
            d="m 2378.8466,2879.6117 c 0,0 70.5662,-72.2667 71.4164,-145.3835"
            id="path2120"
          />
          <path
            d="m 2314.2317,2686.6173 c 0,0 75.6674,57.8133 153.8854,39.9592 78.218,-17.8541 148.7842,-51.8619 171.7395,-117.327 22.9552,-65.465 25.5058,-87.5701 25.5058,-143.683 0,0 45.0604,-15.3035 45.9106,-39.109 0.8502,-23.8054 -19.5545,-51.8619 -56.1129,-57.8133"
            id="path2126"
          />
          <path
            d="m 2531.8818,2305.7298 c 0,0 32.3074,-30.607 53.5623,-33.1576"
            id="path2128"
          />
          <path
            d="m 2632.2048,2536.1327 c 0,0 -35.7082,0.8502 -44.2102,-5.9513 -8.5019,-6.8016 -114.7763,5.1011 -108.825,39.9591 5.9514,34.8581 100.3231,17.8541 113.076,14.4534"
            id="path2130"
          />
          <path
            d="m 2281.9243,2377.9964 c 0,0 -34.858,-32.3074 -59.5137,-5.1012 -24.6556,27.2063 -16.1537,158.9866 38.2588,102.0235"
            id="path2132"
          />
          <path
            d="m 2255.5683,2395.0003 c 0,0 -21.2549,5.1012 -13.6032,18.7043 7.6518,13.6031 7.6518,17.8541 0,24.6557"
            id="path2134"
          />
          <path
            d="m 2529.3312,2119.537 c 0,0 52.7121,51.8619 72.2666,95.2219 19.5545,43.36 41.6595,102.0234 39.9592,136.8814"
            id="path2136"
          />
          <ellipse
            fill="#000000"
            stroke="none"
            id="path2160"
            cx="2088.7102"
            cy="2386.6858"
            rx="13.178028"
            ry="25.177843"
            transform="matrix(1,0,0.20260561,0.97926042,0,0)"
          />
          <g id="g3555">
            <path
              d="m 2506.3759,2136.5409 c 0,0 0.8502,155.5858 -29.7568,196.3951 -30.6071,40.8094 -65.4651,64.6149 -97.7725,70.5663"
              id="path2341"
            />
            <path
              d="m 2354.1909,2422.2066 c 0,0 96.9223,-113.9262 108.825,-237.2045"
              id="path2343"
            />
            <path
              d="m 2329.5353,2381.3972 c 0,0 60.3638,-56.9631 72.2666,-109.6752 11.9027,-52.7121 11.0525,-71.4164 15.3035,-80.7686 4.251,-9.3521 7.6517,-31.4572 4.251,-45.0603"
              id="path2349"
            />
            <path
              d="m 2321.8835,2333.7862 c 0,0 10.2024,39.109 -25.5059,90.1207"
              id="path2351"
            />
            <path
              d="m 2288.7259,2323.5839 c 0,0 84.1693,-81.6188 79.9183,-155.5858"
              id="path2353"
            />
            <path
              d="m 2227.5118,2321.0333 c 0,0 51.0117,-68.0156 64.6149,-79.0682 13.6031,-11.0525 20.4047,-36.5584 22.9552,-63.7646"
              id="path2355"
            />
            <path
              d="m 2163.7472,2406.903 c 0,0 25.5058,-70.5662 27.2062,-90.9709 1.7004,-20.4047 28.9067,-85.0195 66.3153,-104.574"
              id="path2357"
            />
            <path
              d="m 2137.3911,2402.6521 c 0,0 -11.0525,-95.2219 19.5545,-132.6305 30.607,-37.4086 28.9066,-39.9592 31.4572,-49.3113"
              id="path2359"
            />
            <path
              d="m 2474.9187,2077.0272 c 0,0 -120.7278,-56.1129 -276.3135,34.0079 -155.5858,90.1207 -159.8367,310.3213 -132.6305,400.442"
              id="path2361"
            />
            <path
              d="m 2112.7354,2401.8019 c 0,0 4.251,60.3638 10.2024,78.2179 5.9514,17.8541 -0.8502,61.2141 -6.8016,85.0196"
              id="path2363"
            />
            <path
              d="m 2140.7919,2662.8118 c 0,0 28.0564,-58.6634 25.5059,-87.5701 -2.5506,-28.9066 -12.753,-70.5662 -10.2024,-87.5701"
              id="path2365"
            />
            <path
              d="m 2182.4515,2474.9187 c 0,0 39.9591,59.5136 27.2062,93.5215"
              id="path2367"
            />
            <path
              d="m 2189.253,2561.6386 c 0,0 23.8055,28.9066 17.8541,75.6674 -5.9513,46.7607 -0.8502,46.7607 -22.105,69.716"
              id="path2369"
            />
            <path
              d="m 2246.2161,2521.6794 c 0,0 6.8016,57.8133 -1.7004,75.6674"
              id="path2371"
            />
            <path
              d="m 2251.3173,2624.553 c 0,0 12.7529,52.7122 -27.2063,107.1247"
              id="path2373"
            />
          </g>
        </g>
        <g id="pants">
          <path
            d="m 2444.1794,4129.9626 c 0,0 -16.4868,76.2518 -10.3043,138.0775 6.1826,61.8258 29.0462,412.7605 9.0427,507.7768 -20.0034,95.0162 -81.2639,537.5919 -122.5209,591.3511"
            id="path1935"
          />
          <path
            d="m 2179.1227,4523.2737 c 0,0 26.2545,-5.0009 40.0069,-1.2503 13.7523,3.7507 18.7532,-3.7506 40.0068,-11.2519"
            id="path1937"
          />
          <path
            d="m 2206.6274,4542.0269 c 0,0 -8.7515,133.7728 -8.7515,163.778 0,30.0051 -22.5038,162.5278 -36.2562,191.2827 -13.7523,28.7549 -48.7583,136.2733 -50.0085,165.0282 -1.2502,28.7549 -76.2631,266.2956 -108.7686,305.0522"
            id="path1939"
          />
          <path
            d="m 1784.2538,4194.6864 c 0,0 1.7789,113.8507 3.5578,152.9869 1.779,39.1362 5.3368,279.29 -10.6735,327.3207 -16.0102,48.0308 -51.5886,176.1128 -51.5886,217.0279 0,40.9151 -26.6837,238.3749 -48.0307,293.5213 -21.347,55.1464 -53.5575,157.8521 -55.9509,175.803"
            id="path1959"
          />
        </g>
        <g id="g2879">
          <path
            d="m 1745.4276,3966.27 c 0,0 -1.6799,57.117 5.0397,85.6755 6.7197,28.5585 10.0795,77.2759 10.0795,90.7152"
            id="path1961"
          />
          <path
            d="m 1740.8703,4158.0806 c 0,0 23.9412,13.6807 80.3741,8.5504"
            id="path1963"
          />
          <path
            d="m 2053.8165,4161.5007 c 0,0 51.3026,6.8404 105.1704,-8.5504 53.8678,-15.3908 112.0108,-37.6219 134.242,-40.1871 22.2311,-2.5651 152.1979,-36.7669 170.1538,-50.4476"
            id="path1969"
          />
          <path
            d="m 1738.9668,3738.8829 c 0,0 21.9197,-103.336 29.2263,-127.3433 7.3066,-24.0074 13.1432,-54.0061 9.3942,-89.7666 -3.7491,-35.7605 18.7883,-72.022 24.0073,-83.5038"
            id="path1971"
          />
          <path
            d="m 2469.6251,4034.2776 c 0,0 -25.0512,-191.0149 -25.0512,-222.3289 0,-31.3139 -5.219,-128.3871 -8.3504,-164.92 -3.1314,-36.5329 -8.3504,-139.8689 -7.3066,-170.139 1.0438,-30.2701 -5.2189,-61.5841 -2.0875,-94.9856 3.1313,-33.4015 32.3577,-154.482 39.6643,-182.6645 7.3065,-28.1826 27.1387,-50.1023 28.1825,-76.1973 1.0438,-26.0949 2.0876,-66.803 4.1752,-94.9855 2.0876,-28.1826 10.438,-64.7155 1.0438,-87.679"
            id="path1973"
          />
          <path
            d="m 1816.2078,3358.9406 c 0,0 29.2263,-172.2266 43.8395,-203.5405"
            id="path1975"
          />
          <path
            d="m 1883.0108,3250.3856 c 0,0 37.5767,-20.8759 94.9856,-3.1314 57.4089,17.7446 104.3798,35.4892 113.7739,30.2702 9.3942,-5.219 30.2702,-81.4162 33.4016,-111.6864 3.1313,-30.2701 0,-205.6281 -28.1826,-224.4164"
            id="path1977"
          />
          <path
            d="m 1878.8356,3181.495 c 0,0 13.5694,-78.2848 30.2702,-109.5988 16.7007,-31.3139 63.6716,-101.2483 63.6716,-111.6863"
            id="path1979"
          />
          <path
            d="m 1989.4782,2915.3266 c 0,0 37.5767,-68.8906 65.7592,-86.6352 28.1825,-17.7445 145.0879,-67.8468 194.1464,-46.9709"
            id="path1981"
          />
          <path
            d="m 2201.3691,2824.5162 c 0,0 18.7883,65.7593 40.7081,77.241 21.9197,11.4818 89.7666,28.1826 118.9929,26.095 29.2263,-2.0876 76.1972,-6.2628 87.679,-29.2263"
            id="path1983"
          />
          <path
            d="m 2505.1142,2905.9324 c 0,0 70.9782,68.8907 82.46,82.46 11.4817,13.5694 116.9053,72.0221 129.4309,80.3725"
            id="path1985"
          />
          <path
            d="m 2495.72,3239.9477 c 0,0 70.9782,54.2774 89.7666,54.2774 18.7883,0 59.4964,-5.219 64.7154,2.0876 5.219,7.3066 40.7081,-79.3286 44.8833,-107.5111 4.1752,-28.1826 13.9311,-84.8585 38.1103,-109.5118"
            id="path1987"
          />
        </g>
        <g id="RHand">
          <path
            d="m 2733.3186,3114.426 c 0,0 112.0101,103.8142 181.6749,71.0308 0,0 28.6855,-6.8299 50.5411,-128.4018 21.8556,-121.5719 30.0515,-188.5047 35.5154,-204.8964 5.4639,-16.3917 0,-61.469 2.7319,-84.6906 2.732,-23.2216 91.5205,-105.1801 112.0101,-94.2523 20.4897,10.9278 15.0257,54.639 -1.366,64.2009 -16.3917,9.5618 -31.4174,30.0514 -32.7834,42.3452 -1.366,12.2938 1.366,27.3196 -4.0979,46.4432"
            id="path2595"
          />
          <path
            d="m 3134.9157,2689.6074 c 0,0 84.6905,-38.2473 121.5719,-32.7834 36.8813,5.4639 49.1751,28.6855 28.6855,35.5153 -20.4897,6.8299 -102.4483,6.8299 -129.7678,36.8814"
            id="path2597"
          />
          <path
            d="m 3290.637,2690.9734 c 0,0 34.1494,10.9278 34.1494,24.5875 0,13.6598 -10.9278,24.5876 -30.0515,21.8557 -19.1237,-2.732 -99.7163,5.4639 -124.3038,25.9535"
            id="path2599"
          />
          <path
            d="m 3256.4876,2745.6124 c 0,0 64.2009,15.0258 68.2988,34.1494 4.0979,19.1237 -8.1959,27.3196 -28.6855,20.4897 -20.4897,-6.8299 -38.2474,-23.2216 -98.3503,9.5618"
            id="path2601"
          />
          <path
            d="m 3268.7814,2796.1535 c 0,0 40.9792,32.7835 31.4174,49.1752 -9.5618,16.3917 -27.3195,-4.0979 -36.8813,-5.4639 -9.5619,-1.366 -91.0549,20.9614 -127.0358,56.005 -31.9432,31.111 -18.1217,2.3286 -31.9432,31.111 -6.3294,13.1806 -15.1982,67.0173 -25.4279,94.5588 -35.5155,95.6183 -35.8564,171.7639 -81.9585,254.0716 0,0 -47.8092,61.469 -150.2574,24.5876 -94.698,-34.0913 -139.3296,-75.1287 -139.3296,-75.1287"
            id="path2603"
          />
        </g>
        <g id="LHand">
          <path
            d="m 1883.0108,3250.3856 c 0,0 -9.3266,122.2459 -7.4049,149.1501 1.9217,26.9042 -17.2956,84.556 -26.9042,122.9905 -9.6086,38.4346 -106.9337,284.481 -96.8252,340.4664"
            id="path2939"
          />
          <path
            d="m 1754.2092,3887.875 c 0,0 20.9945,97.9744 32.6581,120.5241 11.6637,22.5497 59.8733,200.6144 62.206,244.1586 2.3328,43.5442 44.3218,184.2852 54.4303,213.0555 10.1085,28.7703 17.1066,84.7557 10.886,133.7429"
            id="path2941"
          />
          <path
            d="m 1923.0896,4612.622 c 0,0 1.2322,59.1467 -2.0537,70.6475 -3.2859,11.5007 -27.5196,64.4863 -12.733,69.0045 14.7867,4.5181 32.0378,-6.5719 40.6634,-20.5371 8.6256,-13.9652 20.1263,-42.717 20.1263,-52.9856 0,-10.2685 -0.4107,-67.7722 2.0537,-77.63"
            id="path2947"
          />
          <path
            d="m 1936.2333,4747.3451 c 0,0 -3.2859,35.7345 24.6445,13.5544 27.9304,-22.18 49.6997,-62.4326 50.1104,-80.5052 0.4107,-18.0726 -4.1074,-76.3978 -3.2859,-85.4341"
            id="path2949"
          />
          <path
            d="m 1979.3611,4748.1666 c 0,0 -13.9652,37.7881 21.3586,11.9115 35.3237,-25.8767 49.2889,-74.3442 51.7533,-87.0771 2.4645,-12.733 2.8752,-132.2587 -8.2148,-143.7594"
            id="path2951"
          />
          <path
            d="m 2055.3482,4661.911 c 0,0 35.3238,-35.3238 32.0378,-74.7549 -3.2859,-39.4312 -0.4107,-100.2208 -43.5385,-117.0612"
            id="path2953"
          />
          <path
            d="m 2033.1682,4465.166 c 0,0 -28.3411,-111.3108 -27.1089,-135.9553 1.2322,-24.6445 -68.183,-196.3342 -80.5052,-212.3531 -12.3223,-16.0189 -41.0741,-87.0771 -43.1279,-119.5257 -2.0537,-32.4485 -8.2148,-57.093 -12.7329,-70.6474"
            id="path2955"
          />
          <path
            d="m 1862.7107,3898.7541 c 0,0 41.8956,-131.8479 41.0741,-147.8668 -0.8215,-16.0189 62.4326,-220.5679 66.54,-244.3909 4.1074,-23.823 34.0915,-117.0612 41.8956,-133.0801 7.8041,-16.0189 22.18,-58.736 23.0015,-76.3979"
            id="path2957"
          />
        </g>
      </g>
      <g id="teacher" fill="none" stroke="#000000" strokeWidth={7}>
        <g id="skirt">
          <path
            d="m 4281.781,5365.611 c 0,0 33.9513,-91.4074 40.4804,-104.4656"
            id="path3242"
          />
          <path
            d="m 4446.3143,4506.3819 c 0,0 0,74.4317 -1.3059,116.2179 -1.3058,41.7863 -23.5047,126.6645 -26.1163,148.8634"
            id="path3244"
          />
          <path
            d="m 5244.1698,4452.8434 c 0,0 60.0677,84.8782 31.3396,245.494 -28.728,160.6157 -32.6454,228.5183 -18.2814,302.95 14.364,74.4317 57.456,304.2559 82.2666,349.9595"
            id="path3250"
          />
        </g>
        <g id="book">
          <path
            d="m 4212.699,5181.8013 c 0,0 30.2575,-220.447 121.0298,-320.7287"
            id="path2197"
          />
          <path
            d="m 4235.8747,5179.0799 c 0,0 101.312,-196.1131 117.7375,-311.0913"
            id="path2199"
          />
          <path
            d="m 4235.8747,5179.0799 c 0,0 101.1594,52.7169 210.8676,76.9382 109.7082,24.2212 226.5402,62.6903 255.0358,58.416 28.4957,-4.2744 95.4604,-1.4248 143.903,9.9735"
            id="path3491"
          />
          <path
            d="m 4858.479,5311.476 c 0,0 97.6883,-212.6665 94.2303,-291.3358"
            id="path3493"
          />
          <path
            d="m 4243.821,5123.0155 c 0,0 21.6124,-56.1924 21.6124,-77.8048 0,-21.6125 22.477,-57.9214 30.2574,-62.2439 7.7805,-4.3225 21.6125,-54.4634 21.6125,-68.2954"
            id="path2203"
          />
          <path
            d="m 4276.6719,5089.3001 c 0,0 22.4769,-82.1274 26.7994,-114.1138"
            id="path2205"
          />
          <path
            d="m 4369.1732,4850.6986 c 0,0 78.6693,19.0189 99.4173,29.3929 20.7479,10.374 51.8698,23.3415 67.4308,25.935"
            id="path2207"
          />
          <path
            d="m 4781.5387,4948.3869 c 0,0 153.8808,30.2574 162.5257,51.0054"
            id="path2209"
          />
          <path
            d="m 4353.6122,4867.9886 c 0,0 8.645,-84.7209 57.0569,-25.935 48.4119,58.7859 -63.9729,102.8753 -20.748,28.5284 43.2249,-74.3468 77.8049,-9.5094 73.4824,18.1545 -4.3225,27.6639 -41.4959,56.1924 -20.748,-8.645 20.748,-64.8373 78.6693,-32.8509 73.4823,19.019 -5.1869,51.8699 -37.1734,22.4769 -17.2899,-2.5935 19.8834,-25.0705 92.5013,-54.4634 51.8699,32.8509"
            id="path2395"
          />
          <path
            d="m 4773.4795,4932.2321 c 0,0 29.2977,-53.826 53.826,-10.2202 24.5283,43.6059 -45.6499,124.6856 -27.9351,41.5619 17.7149,-83.1237 93.3439,-31.3417 78.3543,32.023 -14.9895,63.3648 -58.5953,-18.3962 5.4508,-48.3752 64.0461,-29.9791 66.0901,47.6939 52.4633,66.7715 -13.6269,19.0775 -68.8271,-47.7001 13.6268,-51.782 68.8155,-3.4068 3.4437,57.9291 3.4437,57.9291"
            id="path2582"
          />
        </g>
        <g id="hand">
          <path
            d="m 4577.4241,4788.2631 c 0,0 -57.3334,44.3871 -66.5807,62.8817"
            id="path2860"
          />
          <path
            d="m 4582.8076,4905.373 c 0,0 9.6621,40.1349 -23.0404,90.6751 -32.7025,50.5402 -40.1349,81.013 -14.1215,72.0941"
            id="path2865"
          />
          <path
            d="m 4629.6316,4932.1296 c 0,0 -31.9593,78.04 -49.0537,96.621 -17.0945,18.5809 -47.5673,38.6484 -8.9189,40.8781"
            id="path2868"
          />
          <path
            d="m 4591.7264,5071.8584 c 0,0 32.7025,-20.8107 49.797,-48.3105 17.0945,-27.4998 31.216,-57.2293 45.3375,-67.6347"
            id="path2870"
          />
          <path
            d="m 4593.2129,5093.4123 c 0,0 48.3105,11.8918 93.648,-49.797 45.3376,-61.6887 77.2968,-99.5939 78.0401,-144.9314 0.7432,-45.3376 -5.2027,-74.3239 -11.1486,-85.4724"
            id="path2872"
          />
        </g>
        <g id="shirt">
          <path
            d="m 4580.5779,4758.2118 c 0,0 46.824,75.0672 173.1745,54.9997"
            id="path2874"
          />
          <path
            d="m 4753.7524,4813.2115 c 0,0 37.497,-96.3354 51.834,-121.4251 14.337,-25.0898 43.011,-69.893 57.348,-103.0473 14.337,-33.1543 62.7244,-136.2016 77.9575,-165.7717 15.2331,-29.57 54.6599,-120.9685 68.1008,-161.2913 13.4409,-40.3229 16.1291,-66.3087 25.0898,-87.8142 8.9606,-21.5055 17.0252,-50.1795 17.9212,-75.2693 0,0 44.8032,-67.2047 56.452,-201.6142 11.6488,-134.4095 50.1795,-395.1638 58.2441,-427.4221 8.0646,-32.2583 19.7134,-186.3811 19.7134,-203.4063"
            id="path2881"
          />
          <path
            d="m 4799.2594,4690.9327 c 0,0 -88.9827,17.4096 -133.474,1.9344 -44.4913,-15.4752 -38.6881,-11.6064 -59.9665,-25.1473"
            id="path2884"
          />
          <path
            d="m 4621.1288,4679.7229 c 0,0 -21.9209,0.8357 -32.8128,54.688"
            id="path2886"
          />
          <path
            d="m 4632.0663,4659.4103 c 0,0 38.2816,-70.313 51.5629,-144.5324 13.2814,-74.2193 46.0941,-205.4703 72.6568,-259.3769 26.5627,-53.9067 45.3129,-112.5009 69.5318,-160.1575 24.2189,-47.6566 34.3753,-87.5006 77.3443,-146.0948"
            id="path2888"
          />
          <path
            d="m 4861.7556,3986.7489 c 0,0 32.0315,-94.5319 35.1565,-203.9077 3.125,-109.3759 7.8126,-264.8458 15.6251,-302.3461 7.8126,-37.5003 7.0313,-46.0941 -0.7812,-56.2504"
            id="path2890"
          />
          <path
            d="m 5029.7824,4411.3055 c 0,0 29.3094,-1.9871 61.5993,12.916 32.29,14.903 196.7204,-9.4386 181.8174,-51.1672 -14.9031,-41.7286 -52.1607,-150.5209 -54.1478,-174.8626 -1.9871,-24.3416 -10.9289,-128.663 -6.9548,-162.9401 3.9742,-34.277 0.4968,-128.1663 2.9806,-150.0241 2.4839,-21.8578 27.3223,-155.4886 25.3352,-187.2818 -1.987,-31.7932 -3.4774,-186.785 3.9742,-229.0103 7.4515,-42.2254 0.4967,-165.424 -9.4386,-207.1525 -9.9354,-41.7286 -18.3805,-101.8376 -43.2189,-153.5016 -24.8384,-51.6639 -43.2189,-81.47 -71.0379,-110.7794"
            id="path2894"
          />
          <path
            d="m 4713.1199,4381.4026 c 0,0 -75.5539,0.7195 -143.1925,53.967 -67.6387,53.2475 -125.9231,52.5279 -179.1705,27.3433 0,0 17.0183,-93.742 43.8829,-149.461 26.8645,-55.7191 39.7993,-191.0368 35.8194,-247.7508 -3.9799,-56.7141 -16.9147,-377.0987 -7.9599,-428.8378 8.9549,-51.7391 24.8746,-107.4582 65.6689,-187.0568 40.7943,-79.5987 84.5736,-182.0819 89.5485,-226.8562 4.9749,-44.7742 10.9448,-63.6789 17.9097,-76.6137"
            id="path2896"
          />
          <path
            d="m 4665.4759,3117.2821 c 0,0 29.8495,-28.8545 44.7742,-27.8595"
            id="path2898"
          />
          <path
            d="m 4651.5038,3105.2918 c 0,0 4.6324,-38.6038 21.1034,-60.7366 16.471,-22.1329 33.9714,-39.1186 38.0891,-48.8982"
            id="path2900"
          />
          <path
            d="m 4480.7225,4447.6646 c 0,0 74.3803,-106.0608 82.6447,-279.6147 8.2645,-173.554 13.7742,-85.3996 5.5097,-191.4603 -8.2645,-106.0608 -39.945,-356.7498 39.945,-490.3588 79.8899,-133.609 82.6447,-179.0637 90.9092,-217.6312 8.2644,-38.5675 15.1515,-93.664 13.7741,-119.8349"
            id="path2902"
          />
          <path
            d="m 4991.7425,2812.6761 c 0,0 -9.6419,-2.7548 -8.2645,30.3031 1.3774,33.0579 -2.7548,63.3609 -49.5868,101.9285 -46.832,38.5675 -70.2481,53.7191 -96.4189,60.6061 -26.1708,6.8871 -56.4739,-2.7548 -77.1351,9.6419 0,0 -4.3259,30.2552 -11.2616,49.8014 -6.9357,19.5461 -10.7188,56.7467 -11.3494,66.8351 0,0 25.8514,26.4818 73.1404,-3.7832 47.2889,-30.2649 134.9312,-103.4052 170.8708,-117.2767 35.9397,-13.8714 65.5741,-63.6825 73.7709,-75.6623"
            id="path2904"
          />
          <path
            d="m 5055.5083,2935.07 c 0,0 -6.3052,-37.8312 -21.4377,-58.0079 -15.1325,-20.1766 -54.3912,-53.7402 -42.3281,-64.386"
            id="path2906"
          />
        </g>
        <g id="head">
          <path
            d="m 4964.9794,2860.0723 c 0,0 -29.1979,-58.3959 -23.8892,-110.1559"
            id="path3114"
          />
          <path
            d="m 4706.6729,2875.7851 c 0,0 -13.2433,34.4326 9.2703,74.1625 22.5136,39.7299 32.9214,49.7451 40.369,54.4845"
            id="path3121"
          />
          <path
            d="m 4689.439,2861.9431 c 0,0 -0.8296,9.9546 -39.8183,9.9546 -38.9888,0 -118.6255,14.9319 -170.0575,-20.7387 -51.4321,-35.6706 -88.7618,-97.8868 -87.9322,-150.1483"
            id="path3333"
          />
          <path
            d="m 4468.779,2749.9541 c 0,0 14.1024,-19.0797 60.5571,-12.4433 46.4547,6.6364 63.0457,-4.9773 70.5116,-8.2955"
            id="path3335"
          />
          <path
            d="m 4605.9164,2749.6872 c 0,0 -14.0912,-20.5656 -15.2337,-31.61"
            id="path3337"
          />
          <path
            d="m 4391.631,2701.0107 c 0,0 -71.3354,-3.833 -62.1373,-57.6068 9.1982,-53.7737 54.4813,-59.4341 73.5851,-82.7832"
            id="path3339"
          />
          <path
            d="m 4512.749,2295.2897 c 0,0 -38.9152,48.8209 -45.9907,65.8021 -7.0755,16.9812 -14.151,39.6227 -21.934,49.5284 -7.7831,9.9057 -14.151,15.5661 -20.519,50.236 -6.3679,34.6699 -14.3873,75.573 -14.6838,88.9149"
            id="path3341"
          />
          <path
            d="m 4476.0342,2461.715 c 0,0 51.5884,-19.8645 105.2521,33.7993"
            id="path3343"
          />
          <ellipse
            fill="#000000"
            stroke="none"
            id="path3367"
            cx="5626.1118"
            cy="2778.4519"
            rx="9.615468"
            ry="23.080328"
            transform="matrix(1,0,-0.39994444,0.91653938,0,0)"
          />
          <path
            d="m 4778.5028,2512.3294 c 0,0 0,-12.3078 22.3079,-16.154 22.3079,-3.8462 65.3852,-6.9231 79.2315,20.0002 13.8462,26.9233 16.9232,57.6928 -40.0004,86.9238"
            id="path3548"
          />
          <g id="hair">
            <path
              d="m 4902.7351,2622.0261 c 0,0 11.8376,47.3504 39.4586,72.9985 27.621,25.6481 60.1744,44.391 66.0932,54.2556"
              id="path3550"
            />
            <path
              d="m 4969.8147,2546.0683 c 0,0 48.3369,30.5804 12.8241,130.2134"
              id="path3557"
            />
            <path
              d="m 5026.0433,2551.987 c 0,0 13.8105,13.8106 0,86.809"
              id="path3559"
            />
            <path
              d="m 4547.6074,2274.7902 c 0,0 14.797,91.7413 100.6195,130.2134"
              id="path3561"
            />
            <path
              d="m 4626.5055,2394.79 c 0,0 54.0017,70.5766 117.0929,83.4087"
              id="path3563"
            />
            <path
              d="m 4635.0602,2333.3029 c 0,0 11.2281,74.8539 124.5783,126.717"
              id="path3565"
            />
            <path
              d="m 4681.0419,2297.48 c 0,0 108.5382,51.863 136.341,152.9158"
              id="path3567"
            />
            <path
              d="m 4582.6625,2238.6662 c 0,0 42.7737,-52.3977 159.3319,-43.3083 116.5582,9.0894 216.5416,80.7352 256.6419,138.4797"
              id="path3569"
            />
            <path
              d="m 4761.2425,2291.5986 c 0,0 91.4287,81.2699 111.7462,96.7754 20.3175,15.5054 58.8138,63.0911 68.9725,95.1714"
              id="path3571"
            />
            <path
              d="m 4761.7772,2265.9344 c 0,0 187.1347,55.6057 221.8883,182.8574"
              id="path3573"
            />
            <path
              d="m 5008.2604,2351.4817 c 0,0 54.5364,22.4562 65.7645,78.5966 11.2281,56.1404 22.9908,132.5983 6.9507,181.788 -16.0401,49.1897 -66.2992,111.2115 -74.8539,123.509"
              id="path3575"
            />
            <path
              d="m 4946.8421,2260.8654 c 0,0 56.5469,-77.752 163.5821,-21.2051 107.0351,56.5469 107.0351,212.0508 -16.1563,220.1289"
              id="path3577"
            />
            <path
              d="m 5036.7113,2305.2951 c 0,0 123.1914,45.4394 78.7617,125.2109"
              id="path3579"
            />
            <path
              d="m 5023.5843,2272.9826 c 0,0 133.2891,14.1367 136.3184,140.3574"
              id="path3581"
            />
            <path
              d="m 5080.1312,2259.8556 c 0,0 34.332,18.1758 37.3613,35.3418"
              id="path3583"
            />
          </g>
        </g>
      </g>
      <animated.g
        id="frame"
        opacity={frame}
        strokeWidth={7}
        stroke="#000000"
        fill="none"
      >
        <path
          d="m 215.12565,148.36252 c 0,0 2129.00205,37.09062 2403.47275,14.83625 274.4707,-22.25438 1587.4789,-29.67251 1728.4233,-14.83625 140.9444,14.83625 2091.9115,22.25437 2218.0196,0"
          id="path3585"
        />
        <path
          d="m 6565.0413,148.36252 c 0,0 -44.5087,1528.13388 -7.4181,1928.71268 37.0906,400.5788 22.2544,1275.9177 -7.4181,1698.7508 -29.6725,422.8332 -7.4182,1453.9527 7.4181,1602.3152 0,0 -2203.1834,-14.8363 -2477.654,7.4181 -274.4707,22.2544 -2275.9498,3.5007 -2458.4013,-24.2131 -182.4515,-27.7138 -1087.46284,83.5581 -1413.86038,9.3769 0,0 -53.81994,-1899.5661 9.59909,-2336.7885 63.41902,-437.2225 -33.43179,-1997.5492 -8.35795,-2239.92969 25.07384,-242.38045 54.14371,-288.33121 6.17699,-645.64239"
          id="path3594"
        />
      </animated.g>
    </g>
  );
  if (!props.svgPos) {
    return content;
  } else {
    const viewBox = [0, 0, 6780, 5568].join(" ");
    const height = props.svgPos.height || 72;
    const width = props.svgPos.width || 72;
    return (
      <svg
        x={props.svgPos.x}
        y={props.svgPos.y}
        height={height}
        width={width}
        viewBox={viewBox}
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
      >
        {content}
      </svg>
    );
  }
}
